import React from 'react';
// import classNames from 'classnames';
import { Link } from 'react-scroll';

import { ReactComponent as ArrowDown } from './../../assets/arrow-down.svg';

import './slogan-slide.scss';

type SloganData = {
  firstWordSK: string;
  firstWordEN: string;
  secondWordSK: string;
  secondWordEN: string;
  thirdWordSK: string;
  thirdWordEN: string;
};

const SLOGAN_DATA: SloganData = {
  firstWordSK: 'Inšpirovať',
  firstWordEN: 'Inspire',
  secondWordSK: 'Zjednocovať',
  secondWordEN: 'Unite',
  thirdWordSK: 'Povzbudiť',
  thirdWordEN: 'Encourage',
};

export const SloganSlide: React.FC<{ id: string; language: 'en' | 'sk'; isMenuFixed: boolean }> = ({
  id,
  language,
  isMenuFixed,
}) => {
  return (
    <div className="SloganSlide" id={id}>
      <div className="SloganSlide-container">
        <div className="SloganSlide-word1">
          {language === 'en' ? SLOGAN_DATA.firstWordEN : SLOGAN_DATA.firstWordSK}
        </div>
        <div className="SloganSlide-word2">
          {language === 'en' ? SLOGAN_DATA.secondWordEN : SLOGAN_DATA.secondWordSK}
        </div>
        <div className="SloganSlide-word3">
          {language === 'en' ? SLOGAN_DATA.thirdWordEN : SLOGAN_DATA.thirdWordSK}
        </div>
      </div>
      <Link
        className="SloganSlide-buttonContainer"
        to="cs-goals"
        smooth={true}
        offset={isMenuFixed ? -80 : -160}
      >
        <ArrowDown className="SloganSlide-button" />
      </Link>
    </div>
  );
};
